<template>
  <div class="body">
    <b-row>
      <b-col cols="12">
        <b-card :title="!newRole ? name : 'New Role'">
          <div
            v-if="loading"
            class="d-flex justify-content-center align-items-center mb-1 mt-5 rule-body-wrapper"
          >
            <b-spinner label="Loading..." />
          </div>
          <div
            v-if="!loading"
            class="rule-body-wrapper"
          >
            <!-- form -->
            <validation-observer ref="newRoleForm">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-checkbox-group
                  v-model="permissions"
                >
                  <b-row>
                    <b-col
                      v-for="(rule,rind) in rulesOptions"
                      :key="'rule'+rind"
                      cols="12"
                      lg="6"
                    >
                      <div class="permissions-group mt-2">
                        <div class="permissions-group-title">
                          {{ rule.ruleName }}
                        </div>
                        <ul class="list-unstyled row">
                          <li
                            v-for="(permission, pind) in rule.options"
                            :key="'ruleOption'+pind"
                            class="col-lg-4 col-md-12 col-sm-12 col-xs-12"
                          >
                            <b-form-checkbox
                              :value="permission.value"
                              class="m-1"
                            >{{ permission.permission }}</b-form-checkbox>
                          </li>
                        </ul>
                      </div>
                    </b-col>
                  </b-row>
                </b-form-checkbox-group>

                <b-row class="mt-2">
                  <b-col cols="12">
                    <b-button
                      variant="primary"
                      type="submit"
                      class="float-right"
                      @click.prevent="validateForm"
                    >
                      Save
                    </b-button>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </div>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BRow, BSpinner, BFormCheckboxGroup,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BButton,
    BCol,
    BRow,
    BFormCheckbox,
    BSpinner,
    BFormCheckboxGroup,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      required,
      name: null,
      newRole: true,
      permissions: [],
      rulesOptions: [
        {
          ruleName: 'Admins',
          options: [
            { permission: 'Create', value: 'Create Admin' },
            { permission: 'View', value: 'View Admins' },
            { permission: 'Edit', value: 'Edit Admin' },
            { permission: 'Delete', value: 'Delete Admin' },
            { permission: 'Change Password', value: 'Change Admin Password' },
            { permission: 'Activate', value: 'Activate Admin' },
            { permission: 'Deactivate', value: 'Deactivate Admin' },
          ],
        },
        {
          ruleName: 'Roles',
          options: [
            { permission: 'Create', value: 'Create Role' },
            { permission: 'View', value: 'View Roles' },
            { permission: 'Edit', value: 'Edit Role' },
          ],
        },
        {
          ruleName: 'Customers',
          options: [
            { permission: 'View', value: 'View Customers' },
          ],
        },
        {
          ruleName: 'Rides',
          options: [
            { permission: 'View', value: 'View Rides' },
          ],
        },
        {
          ruleName: 'Scooters',
          options: [
            { permission: 'View', value: 'View Scooters' },
            { permission: 'View Pin', value: 'View Scooter Pin' },
          ],
        },
        {
          ruleName: 'Service Locator',
          options: [
            { permission: 'Create', value: 'Create Service Locator' },
            { permission: 'Edit', value: 'Edit Service Locator' },
            { permission: 'View', value: 'View Service Locators' },
          ],
        },
        {
          ruleName: 'Logs',
          options: [
            {
              permission: 'View', value: 'View Logs',
            },
          ],
        },
        {
          ruleName: 'General',
          options: [
            { permission: 'View Stores', value: 'View Stores' },
            { permission: 'View Factory Monitor', value: 'View Factory Monitor' },
            { permission: 'View Statistics', value: 'View Statistics' },
            { permission: 'View subscriptions', value: 'View subscriptions' },
          ],
        },

        {
          ruleName: 'Maintenance',
          options: [
            { permission: 'View Maintenance', value: 'View Maintenance' },
          ],
        },
        {
          ruleName: 'Release Notes',
          options: [
            { permission: 'Edit Release Notes', value: 'Edit Release Notes' },
          ],
        },
        {
          ruleName: 'Access Technician App',
          options: [
            { permission: 'Access Technician App', value: 'Access Technician App' },
          ],
        },
      ],
      loading: false,
    }
  },
  mounted() {
    this.checkIfNewRole()
  },
  methods: {
    checkIfNewRole() {
      if (this.$route.params.id) {
        this.newRole = false
        this.load()
      }
    },
    load() {
      this.loading = true
      this.$http.get(this.$resources.roles.fetch.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status, data: { role } } } = response
        if (status === 200) {
          this.name = role.name
          this.permissions = role.permissions
          this.loading = false
        }
      })
    },
    validateForm() {
      this.$refs.newRoleForm.validate()
        .then(success => {
          if (success) {
            this.loading = true

            const { id } = this.$route.params

            this.$http.post(id ? this.$resources.roles.update.replace(':id', id) : this.$resources.roles.store, {
              name: this.name,
              permissions: this.permissions,
            })
              .then(response => {
                const {
                  data: {
                    status,
                  },
                } = response

                if (status === 200) {
                  this.$notify({ type: 'success', title: id ? 'Update role' : 'Add role', text: id ? 'Role updated successfully ' : 'Role added successfully' })
                  this.$router.push({ name: 'roles' })
                } else if (status === 1001) {
                  this.$notify({ type: 'danger', title: 'Validation error', text: 'Name is used' })
                } else {
                  this.$notify({ type: 'danger', title: 'Server error', text: 'Service is under-maintenance currently' })
                }
                this.loading = false
              })
              .catch(() => {
                this.$notify({ type: 'danger', title: 'Server error', text: 'Service is under-maintenance currently' })
                this.loading = false
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.permissions-group {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 10px;
}

.rule-body-wrapper {
  min-height: 927px !important;
}

</style>
